import { useMediaQuery } from "@mui/material";
import { useClickAway } from "@uidotdev/usehooks";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import Color from "../../../assets/colors";
import tabLoading from "../../../assets/lotties/job-list/jlp-loading.json";
import { formJobQueryParam } from "../../../functions/job";
import { calibrateSalary } from "../../../helpers/data_management";
import { getJobDiscovery, posthogJobApply } from "../../../helpers/posthog";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import { updateJobAppliedDialog } from "../../../redux/actions/dialog_action";
import {
  applyJob,
  bookmarkJob,
  clearJobList,
  getAshleyAwsRecommendedJobs,
  getAshleyRecommendedJobs,
  getJobs,
  getJobsParams,
  getRecentlyViewedJobs,
  unBookmarkJob,
  updateFetchingForYou,
  updateFypTab,
  updateJobListFilter,
  updateJobListPage,
  updateJobListTab,
  updateLoadingJobs,
  updateSelectedJob,
  updateSelectedJobId,
  updateViewHistory,
} from "../../../redux/actions/job_action";
import {
  updateShowSignInModalStatus,
  updateSignInText,
  updateSignUpText,
} from "../../../redux/actions/navbar_action";
import {
  checkAuthentication,
  getUser,
  updateCreateResumePopupStatus,
  updateInReviewResumePoupupStatus,
  updateJobCountryPreference,
  updateJobCountryPreferenceDialog,
  updateNationalityDialog,
  updateRejectedResumePopupStatus,
} from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import * as jobTypes from "../../../redux/types/job_type";
import AccordionForYou from "../../jobs/AccordionForYou/AccordionForYou";
import AccordionJobList from "../../jobs/AccordionJobList/AccordionJobList";
import JobAlertsCreateDialog from "../../profile/JobAlertsCreateDialog/JobAlertsCreateDialog";
import CreateResumePopup from "../../profile/ResumePopups/ApplyJobResumeStatus/CreateResume/CreateResume";
import RejectedResumePopup from "../../profile/ResumePopups/ApplyJobResumeStatus/RejectedResume/RejectedResume";
import ResumeInReviewPopup from "../../profile/ResumePopups/ApplyJobResumeStatus/ResumeInReview/ResumeInReview";
import SharedNationalityDialog from "../../shared/SharedNationalityDialog/SharedNationalityDialog";
import SharedNavbar2 from "../../shared/SharedNavbar/Navbar/Navbar";
import JobAlertDialog from "../JobAlertDialog/JobAlertDialog";
import JobAppliedDialog from "../JobAppliedDialog/JobAppliedDialog";
import JobCountryPreferenceDialog from "../JobCountryPreferenceDialog/JobCountryPreferenceDialog";
import JobSearchOptions from "../JobSearchOptions/JobSearchOptions";
import SearchBar from "../SearchBar/SearchBar";
import VisitorJobAlertDialog from "../VisitorJobAlertDialog/VisitorJobAlertDialog";
import VisitorJobAlertSuccesDialog from "../VisitorJobAlertDialog/VisitorJobAlertSuccessDialog/VisitorJobAlertSuccessDialog";
import {
  BoxContainer,
  ButtonStyled,
  DownArrowIcon,
  ForYouOptionsContainer,
  FypIcon,
  HistoryIcon,
  InfoIcon,
  JobListFiltersContainer,
  SearchIcon,
  SelectContainer,
  SelectDropdown,
  SelectHeader,
  SelectOption,
  TabContainer,
  TabContent,
  TabGroup,
  TabNavWrapper,
  TabSlider,
  TabTitle,
  TabWrapper,
  TextStyled,
  TooltipStyled,
  Wrapper,
} from "./newstyles";

let isSavingJob = false;
let isApplyingJob = false;

function NewJobListWrapper(props) {
  const { filterParams, pageParams, isLoggedIn } = props;

  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const isMobile = useMediaQuery("(max-width: 640px)");

  const dispatch = useDispatch();
  const [triggerSnackbarFunc] = useTriggerSnackbar();

  const user = useSelector((state) => state.user.user);
  const job = useSelector((state) => state.jobs.job);
  const jobListTab = useSelector((state) => state.jobs.jobListTab);
  const fypTab = Number(useSelector((state) => state.jobs.fypTab));
  const viewHistoryState = useSelector((state) => state.jobs.viewHistoryState);
  const isLoadingJobs = useSelector((state) => state.jobs.isLoadingJobs);

  const firstTimeMountLoadingJobs = useRef(true);

  const [appliedJob, setAppliedJob] = useState({});
  const [isTabLoading, setIsTabLoading] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [jobPreferenceToggle, setJobPreferenceToggle] = useState(false);

  const resumeUploaded = user?.resume?.length > 1;
  const resumeExist = user?.resume?.length > 1 && user?.state === "approved";
  const activityExist = useSelector((state) => state.jobs.activityExist);

  const jobDiscovery = getJobDiscovery(jobListTab, viewHistoryState, fypTab);

  function handleTabChange(newValue) {
    if (newValue === jobListTab) {
      return;
    }
    scrollTo(0, 0);
    dispatch(updateSelectedJob({}));
    dispatch(updateJobListTab(newValue));
    dispatch(updateViewHistory(false));
    if (newValue === 1) {
      setShowDropdown(false);
    }
  }

  function handleFypTabChange(newTab) {
    setShowDropdown(false);

    if (newTab === fypTab && !viewHistoryState) {
      return;
    }

    scrollTo(0, 0);
    dispatch(updateViewHistory(false));
    dispatch(updateFypTab(newTab));

    if (!(resumeExist || activityExist)) {
      return;
    }

    dispatch(updateJobListPage(1));
    const jobKey = new Date().getTime();
    const params = {
      currentLoadingJobsKey: jobKey,
      first: 30,
      last: null,
      startCursor: null,
      endCursor: null,
      refresh: newTab === 1 ? true : false,
    };

    switch (newTab) {
      case 1: {
        dispatch(getAshleyRecommendedJobs(params));
        break;
      }
      case 2: {
        dispatch(getAshleyAwsRecommendedJobs(params));
        break;
      }
      default:
        break;
    }
  }

  function handleViewHistory() {
    scrollTo(0, 0);
    dispatch(updateFetchingForYou(true));

    if (!viewHistoryState) {
      dispatch(updateJobListPage(1));
      const jobKey = new Date().getTime();
      const params = {
        currentLoadingJobsKey: jobKey,
        first: 30,
        last: null,
        startCursor: null,
        endCursor: null,
      };
      dispatch(getRecentlyViewedJobs(params));
      dispatch(updateViewHistory(true));
    } else {
      handleFypTabChange(fypTab);
      dispatch(updateViewHistory(false));
    }

    setShowDropdown(false);
  }

  function isSignedIn(showPopUp) {
    let signedIn = checkAuthentication();

    if (!signedIn && showPopUp) {
      dispatch(updateShowSignInModalStatus(true));
    }

    return signedIn;
  }

  function loadJobs(params) {
    const pagination = {
      first: 30,
      last: null,
      startCursor: pageParams ?? null,
      endCursor: null,
    };

    dispatch(getJobsParams(params));

    if (pageParams) {
      const currentPage = Number(atob(pageParams)) / 30;
      dispatch(updateJobListPage(currentPage));
    } else {
      dispatch(updateJobListPage(1));
    }

    dispatch(getJobs(params, pagination))
      .then((response) => {
        if (response.type === "FETCH_JOBS_SUCCEED") {
          triggerSnackbarFunc({
            snackbarMessage: "Your page is now reset to the default search",
            severity: "ashley",
          });
        }
        store.getState().jobs.isLoadingJobs = false;
      })
      .catch((error) => {
        store.getState().jobs.isLoadingJobs = false;
      });
  }

  function clearAndLoadJobs(params) {
    dispatch(clearJobList()).then((res) => {
      loadJobs(params);
    });
  }

  function loadingJobs(refresh) {
    if (isLoadingJobs && !refresh) return;

    dispatch(updateLoadingJobs(true));

    // Cancel any existing call to graphQL
    if (store.getState().axios.cancelTokens[jobTypes.FETCHING_JOBS_KEY]) {
      store
        .getState()
        .axios.cancelTokens[jobTypes.FETCHING_JOBS_KEY].cancel(
          "job list search"
        );
    }

    let currentLoadingJobsKey = new Date().getTime();
    store.getState().jobs.currentLoadingJobsKey = currentLoadingJobsKey;

    let params = formJobQueryParam(
      store.getState().jobs.jobListFilter,
      refresh
    );

    if (firstTimeMountLoadingJobs.current === false) {
      let preProcessStateRegion;

      if (
        params.stateRegions &&
        Array.isArray(params.stateRegions) &&
        params.stateRegions.join(", ").length !== 0
      ) {
        // If stateRegions is array and joined string length is not 0
        preProcessStateRegion = params.stateRegions;
      } else if (
        params.stateRegions &&
        Array.isArray(params.stateRegions) &&
        params.stateRegions.length === 0
      ) {
        // If stateRegions is array and joined string length is 0
        preProcessStateRegion = null;
      } else {
        preProcessStateRegion = null;
      }

      let reduxJobTracks = store?.getState()?.jobs?.tracks;
      let reduxJobTypes = store?.getState()?.jobs?.jobTypes;
      let reduxJobExpLevel = store?.getState()?.jobs?.experienceLevels;

      const regex = /\d+(\.\d+)?/g;
      const selectedExp =
        reduxJobExpLevel
          .filter((exp) => params?.experienceIds?.includes(parseInt(exp.id)))
          .map((match) =>
            match.title.toLowerCase().replace(/intern|fresh graduate/g, "0")
          ) ?? [];

      const cleanSelectExp = selectedExp.join().match(regex)?.sort() ?? [];

      sendTrackingEvent({
        event: "CE_apply-filter-job",
        "search-term":
          params.keyword && params.keyword !== "*" ? params.keyword : null,
        specialisation: params.trackIds ?? null,
        states: preProcessStateRegion ?? null,
        "job-type": params.jobTypeIds ?? null,
        experience: params.experienceIds ?? null,
        salary:
          params.expectedSalary &&
          Number.isInteger(params.expectedSalary) &&
          params.expectedSalary > 0
            ? calibrateSalary(params.expectedSalary)
            : null,

        // ct-specialization: {{array of string}}
        "ct-specialization":
          reduxJobTracks
            .filter((track) => params?.trackIds?.includes(parseInt(track.id)))
            .map((match) => match.slug) ?? [],

        // ct-job-types: {{array of string}}
        "ct-job-types":
          reduxJobTypes
            .filter((type) => params?.jobTypeIds?.includes(parseInt(type.id)))
            .map((match) => match.title) ?? [],

        "ct-min-exp": parseInt(cleanSelectExp[0] ?? 0) ?? 0,

        "ct-max-exp":
          parseInt(cleanSelectExp[cleanSelectExp.length - 1] ?? 0) ?? 0,
      });
    } else {
      firstTimeMountLoadingJobs.current = false;
    }

    if (refresh) {
      clearAndLoadJobs(params);
    } else {
      loadJobs(params);
    }
  }

  function onApplyJobClicked(job, reduxUser) {
    if (!checkAuthentication()) {
      dispatch(updateShowSignInModalStatus(true));
      dispatch(updateSelectedJobId(job?.id));
      return;
    }

    if (
      !reduxUser?.nationality ||
      !reduxUser?.mobileNumber ||
      !reduxUser?.currentLocation ||
      reduxUser?.nationality === "undefined" ||
      reduxUser?.nationality === "null" ||
      reduxUser?.mobileNumber === "undefined" ||
      reduxUser?.mobileNumber === "null" ||
      reduxUser?.currentLocation === "undefined" ||
      reduxUser?.currentLocation === "null"
    ) {
      const params = {
        showNationalityDialog: true,
      };

      dispatch(updateNationalityDialog(params));
    } else {
      // CONDITION - RIGHT TO WORK OR RELOCATION PREFERENCE
      if (process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my") {
        if (reduxUser.nationality != "Malaysian" || true) {
          if (
            Object.keys(reduxUser?.rightToWork).length === 0 ||
            Object.keys(reduxUser?.relocationPreferences).length === 0
          ) {
            dispatch(updateJobCountryPreference("my")).then(() => {
              dispatch(updateJobCountryPreferenceDialog(true));
            });
            return;
          }

          // CHECK IF RIGHT TO WORK MORE THAN 3 MONTHS
          dayjs.extend(utc);
          const currentTimestamp = dayjs.utc().format();
          const dateAfterThreeMonths = dayjs
            .utc(reduxUser?.rightToWork?.MY?.timestamp)
            .add(3, "months")
            .format();
          const rightToWorkCheck =
            dayjs(currentTimestamp).isAfter(dateAfterThreeMonths);

          if (rightToWorkCheck) {
            dispatch(updateJobCountryPreference("my")).then(() => {
              dispatch(updateJobCountryPreferenceDialog(true));
            });
            return;
          }
        }
      }

      if (process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "sg") {
        if (reduxUser.nationality != "Singaporean") {
          if (
            Object.keys(reduxUser?.rightToWork).length === 0 ||
            Object.keys(reduxUser?.relocationPreferences).length === 0
          ) {
            dispatch(updateJobCountryPreference("sg")).then(() => {
              dispatch(updateJobCountryPreferenceDialog(true));
            });
            return;
          }

          dayjs.extend(utc);
          const currentTimestamp = dayjs.utc().format();
          const dateAfterThreeMonths = dayjs
            .utc(reduxUser?.rightToWork?.SG?.timestamp)
            .add(3, "months")
            .format();
          const rightToWorkCheck =
            dayjs(currentTimestamp).isAfter(dateAfterThreeMonths);
          if (rightToWorkCheck) {
            dispatch(updateJobCountryPreference("sg")).then(() => {
              dispatch(updateJobCountryPreferenceDialog(true));
            });
            return;
          }
        }
      }

      setAppliedJob(job);

      return new Promise((resolve) => {
        if (!isSignedIn(true) || isApplyingJob) {
          if (!isSignedIn(false)) {
            dispatch(updateSignInText("Log In to Apply for Jobs"));
            dispatch(updateSignUpText("Sign Up to Apply for Jobs"));
          }

          sendTrackingEvent({
            event: "applied-job",
            "job-title": job.title,
            resume: false,
            logged_in: false,
            scraped: job.scraped !== "",
            "job-bucket-id": null,
            "job-id": job?.id,
          });
          return resolve(false);
        }

        const user = getUser();

        // Do not send in application if user has no resume/rejected
        if (
          !user?.resume ||
          user?.state == "rejected" ||
          user?.state == "no_resume"
        ) {
          if (!user?.resume || user?.state == "no_resume") {
            dispatch(updateCreateResumePopupStatus(true));
          } else if (user?.state == "rejected") {
            dispatch(updateRejectedResumePopupStatus(true));
          }

          sendTrackingEvent({
            event: "applied-job",
            "job-title": job.title,
            resume: false,
            logged_in: true,
            scraped: job.scraped !== "",
            "job-bucket-id": null,
            "job-id": job?.id,
          });

          return resolve(false);
        }

        let jobId = null;

        if (job) {
          if (job?.category === "scraped" || job?.category === "aggregated") {
            posthogJobApply(job, "job_list", jobDiscovery);

            sendTrackingEvent({
              event: "CE_job-apply",
              origin: "job_list",
              "job-discovery": jobDiscovery,
              "job-title": job.title,
              "job-id": job?.id,
              resume: false,
              logged_in: true,
              scraped: job.scraped !== "",
              job_preference_toggle: jobPreferenceToggle ? "on" : "off",
              "job-bucket-id": null,
            });

            window.open(job.externalJobUrl, "_blank");
          } else {
            if (job.id) {
              setCurrentId(job.id);
              jobId = job.id;
            } else {
              setCurrentId(job);
              jobId = job;
            }
            isApplyingJob = true;

            let params = {
              jobId: jobId,
              canCancel: false,
              origin: "job_list",
              jobDiscovery: jobDiscovery,
            };

            dispatch(applyJob(params))
              .then((response) => {
                isApplyingJob = false;

                if (response.type == jobTypes.APPLY_JOB_SUCCEED) {
                  sendTrackingEvent({
                    event: "CE_job-apply",
                    origin: "job_list",
                    "job-discovery": jobDiscovery,
                    "job-title": job.title,
                    "job-id": job?.id,
                    resume: true,
                    logged_in: true,
                    scraped: job.scraped !== "",
                    job_preference_toggle: jobPreferenceToggle ? "on" : "off",
                    "job-bucket-id": response?.payload?.jobBucketId ?? null,
                  });

                  if (user?.state == "in_review") {
                    dispatch(updateInReviewResumePoupupStatus(true));
                  } else {
                    dispatch(updateJobAppliedDialog(true));
                  }

                  triggerSnackbarFunc({
                    snackbarMessage: "Your application has been sent!",
                    severity: "success",
                  });

                  if (job.external_job_url || job.externalJobUrl) {
                    setTimeout(() => {
                      window.open(job.external_job_url || job.externalJobUrl);
                    }, 1500);
                  }
                }
                return resolve(true);
              })
              .catch((error) => {
                isApplyingJob = false;
                return resolve(false);
              });
          }
        }
      });
    }
  }

  function onFollowJobClicked(jobId, origin) {
    return new Promise((resolve) => {
      if (!isSignedIn(true) || isSavingJob) {
        if (!isSignedIn(false)) {
          dispatch(updateSignInText("Log In to Save Jobs"));
          dispatch(updateSignUpText("Sign Up to Save Jobs"));
        }
        return resolve(false);
      }

      if (job?.id === jobId && job?.bookmark) {
        unSaveJob(jobId);
        return;
      }

      if (jobId) {
        setCurrentId(jobId);
        isSavingJob = true;

        let params = {
          jobId: jobId,
          canCancel: false,
          origin: origin ? "recommended-job" : "job-list",
        };

        dispatch(bookmarkJob(params))
          .then((response) => {
            isSavingJob = false;
            if (response.type == jobTypes.BOOKMARK_JOB_SUCCEED) {
              triggerSnackbarFunc({
                snackbarMessage: "Saved job successfully",
                severity: "success",
              });
              return resolve(true);
            }
          })
          .catch((error) => {
            isSavingJob = false;
            return resolve(false);
          });
      }
    });
  }

  function unSaveJob(jobId, origin) {
    return new Promise((resolve) => {
      if (!isSignedIn(true) || isSavingJob) return resolve(false);

      if (jobId) {
        setCurrentId(jobId);
        isSavingJob = true;

        let params = {
          jobId: jobId,
          canCancel: false,
          origin: origin ? "recommended-job" : "job-list",
        };
        dispatch(unBookmarkJob(params))
          .then((response) => {
            isSavingJob = false;
            if (response.type == jobTypes.UNBOOKMARK_JOB_SUCCEED) {
              triggerSnackbarFunc({
                snackbarMessage: "Unsaved job successfully",
                severity: "success",
              });
            }
            return resolve(true);
          })
          .catch((error) => {
            isSavingJob = false;
            return resolve(false);
          });
      }
    });
  }

  async function updateJobListFilterFunction(key, value) {
    let jobFilter = JSON.parse(
      JSON.stringify(store.getState().jobs.jobListFilter)
    );

    if (key == "keyword" || key == "expectedSalary" || key == "globalHire") {
      jobFilter[key] = value;
    } else if (value == -1) {
      key = getFilterKey(key);
      jobFilter[key] = [];
    } else {
      key = getFilterKey(key);
      let currentArray = jobFilter[key] ? jobFilter[key] : [];
      let index = currentArray.indexOf(value);
      if (index != -1) {
        currentArray = currentArray.filter((arrayValue) => {
          return arrayValue != value;
        });
      } else {
        currentArray.push(value);
      }
      jobFilter[key] = currentArray;
    }

    dispatch(updateJobListFilter(jobFilter));
  }

  function getFilterKey(key) {
    switch (key) {
      case "specialization": {
        return "specialisationIds";
      }
      case "track": {
        return "trackIds";
      }
      case "state": {
        return "stateRegions";
      }
      case "job-type": {
        return "jobTypeIds";
      }
      case "experience": {
        return "experienceIds";
      }
    }
  }

  useEffect(() => {
    if (filterParams !== undefined && pageParams !== undefined) {
      const pageExist =
        pageParams !== null && Object.keys(pageParams).length >= 1;

      if (
        Object.keys(filterParams).length >= 1 ||
        pageExist ||
        !isLoggedIn ||
        !resumeUploaded
      ) {
        dispatch(updateJobListTab(1));
      } else {
        if (process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "sg") {
          dispatch(updateJobListTab(1));
        } else {
          dispatch(updateJobListTab(0));
        }
      }
      setIsTabLoading(false);
    }
  }, [isLoggedIn, filterParams]);

  return (
    <Wrapper>
      <SharedNavbar2 page={"job-list-page"} />
      <TabNavWrapper>
        {isTabLoading ? (
          <TabWrapper>
            <TabGroup>
              <TabComponent disabled>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: tabLoading,
                  }}
                  width={"55px"}
                />
              </TabComponent>
            </TabGroup>
          </TabWrapper>
        ) : (
          <TabWrapper>
            <TabGroup>
              {process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my" ? (
                <TabComponent
                  currentTab={0}
                  selectedTab={jobListTab}
                  handleTabChange={handleTabChange}
                  tabsIcon={<FypIcon />}
                  tabsText={"Jobs for you"}
                >
                  {jobListTab === 0 && isDesktop && (
                    <ForYouOptionsContainer>
                      <ForYouTooltip />
                      <ForYouDropdown
                        isLoggedIn={isLoggedIn}
                        showDropdown={showDropdown}
                        setShowDropdown={setShowDropdown}
                        fypTab={fypTab}
                        handleFypTabChange={handleFypTabChange}
                      />
                    </ForYouOptionsContainer>
                  )}
                </TabComponent>
              ) : null}
              <TabComponent
                currentTab={1}
                selectedTab={jobListTab}
                handleTabChange={handleTabChange}
                tabsIcon={<SearchIcon />}
                tabsText={"Search"}
              >
                {jobListTab === 1 && isDesktop && (
                  <BoxContainer
                    width={"100%"}
                    gap={"0.5rem"}
                    margin={"0 0 0 1.5rem"}
                  >
                    <SearchBar searchKeyword={filterParams?.keyword} />
                    <JobSearchOptions
                      updateJobListFilterFunction={updateJobListFilterFunction}
                      jobPreferenceToggle={jobPreferenceToggle}
                      setJobPreferenceToggle={setJobPreferenceToggle}
                      isSignedIn={isSignedIn}
                    />
                  </BoxContainer>
                )}
              </TabComponent>
            </TabGroup>
            {jobListTab === 0 && isDesktop && isLoggedIn && (
              <ButtonStyled
                onClick={handleViewHistory}
                selected={viewHistoryState}
              >
                View History <HistoryIcon />
              </ButtonStyled>
            )}
          </TabWrapper>
        )}
      </TabNavWrapper>
      {jobListTab === 0 && !isTabLoading ? (
        <>
          {!isDesktop && isLoggedIn ? (
            <ForYouOptionsContainer>
              <BoxContainer alignItems={"center"} gap={"1rem"} flexGrow={10}>
                <ForYouTooltip />
                <ForYouDropdown
                  isLoggedIn={isLoggedIn}
                  showDropdown={showDropdown}
                  setShowDropdown={setShowDropdown}
                  fypTab={fypTab}
                  handleFypTabChange={handleFypTabChange}
                />
              </BoxContainer>
              <ButtonStyled
                onClick={handleViewHistory}
                selected={viewHistoryState}
              >
                View History <HistoryIcon />
              </ButtonStyled>
            </ForYouOptionsContainer>
          ) : null}
          <AccordionForYou
            isTabLoading={isTabLoading}
            isLoggedIn={isLoggedIn}
            onFollowJobClicked={onFollowJobClicked}
            unSaveJob={unSaveJob}
            currentId={currentId}
            onApplyJobClicked={onApplyJobClicked}
          />
        </>
      ) : jobListTab === 1 && !isTabLoading ? (
        <>
          {!isDesktop ? (
            <JobListFiltersContainer>
              {!isMobile ? (
                <SearchBar searchKeyword={filterParams?.keyword} />
              ) : null}
              <JobSearchOptions
                updateJobListFilterFunction={updateJobListFilterFunction}
                jobPreferenceToggle={jobPreferenceToggle}
                setJobPreferenceToggle={setJobPreferenceToggle}
                isSignedIn={isSignedIn}
              />
            </JobListFiltersContainer>
          ) : null}
          <AccordionJobList
            isTabLoading={isTabLoading}
            filterParams={filterParams}
            loadingJobs={loadingJobs}
            onFollowJobClicked={onFollowJobClicked}
            unSaveJob={unSaveJob}
            currentId={currentId}
            onApplyJobClicked={onApplyJobClicked}
            jobPreferenceToggle
          />
        </>
      ) : null}
      <CreateResumePopup />
      <RejectedResumePopup />
      <ResumeInReviewPopup job={appliedJob} />

      <JobAlertDialog />
      <JobAppliedDialog
        job={appliedJob}
        onFollowJobClicked={onFollowJobClicked}
        unSaveJob={unSaveJob}
      />
      <SharedNationalityDialog
        onApplyJobClicked={onApplyJobClicked}
        job={job}
        useLocalApply={true}
        origin={"job_list"}
      />
      <JobCountryPreferenceDialog
        onApplyJobClicked={onApplyJobClicked}
        job={job}
        useLocalApply={true}
        origin={"job_list"}
      />
      <JobAlertsCreateDialog />
      <VisitorJobAlertDialog />
      <VisitorJobAlertSuccesDialog />
    </Wrapper>
  );
}

function TabComponent({ children, ...props }) {
  const {
    currentTab,
    selectedTab,
    handleTabChange,
    tabsText,
    tabsIcon,
    disabled,
  } = props;

  return (
    <TabContainer
      onClick={() => handleTabChange(currentTab)}
      selectedTab={selectedTab}
      currentTab={currentTab}
      $disabled={disabled}
    >
      <TabContent currentTab={currentTab}>
        <TabTitle selected={selectedTab === currentTab}>
          {tabsIcon}
          {tabsText}
        </TabTitle>
        {children}
      </TabContent>
      <TabSlider
        selectedTab={selectedTab}
        selected={selectedTab === currentTab}
      />
    </TabContainer>
  );
}

function ForYouTooltip() {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const isDesktop = useMediaQuery("(min-width: 1280px)");

  const tooltipRef = useClickAway(() => {
    setIsTooltipOpen(false);
  });

  function handleTooltipClick() {
    setIsTooltipOpen(!isTooltipOpen);
  }

  return isDesktop ? (
    <TooltipStyled
      PopperProps={{
        sx: {
          margin: "0 0 0 -5.5rem !important",
        },
      }}
      componentsProps={{
        tooltip: {
          sx: {
            width: "200px",
            borderRadius: "0.5rem",
            border: "2px solid",
            color: Color.black,
            backgroundColor: Color.white,
            padding: "0.25rem 0.5rem",
          },
        },
      }}
      title={
        <TextStyled
          fontSize={"0.625rem"}
          fontWeight={"500"}
          lineHeight={"14px"}
        >
          Job recommendations based on your profile & activity.
        </TextStyled>
      }
    >
      <InfoIcon />
    </TooltipStyled>
  ) : (
    <TooltipStyled
      ref={tooltipRef}
      open={isTooltipOpen}
      PopperProps={{
        sx: {
          margin: "0 !important",
        },
      }}
      componentsProps={{
        tooltip: {
          sx: {
            width: "200px",
            borderRadius: "0.5rem",
            border: "2px solid",
            color: Color.black,
            backgroundColor: Color.white,
            padding: "0.25rem 0.5rem",
          },
        },
      }}
      title={
        <TextStyled
          fontSize={"0.625rem"}
          fontWeight={"500"}
          lineHeight={"14px"}
        >
          Job recommendations based on your profile & activity.
        </TextStyled>
      }
    >
      <InfoIcon onClick={() => handleTooltipClick()} />
    </TooltipStyled>
  );
}

function ForYouDropdown(props) {
  const {
    isLoggedIn,
    showDropdown,
    setShowDropdown,
    fypTab,
    handleFypTabChange,
  } = props;

  const dropdownOptions = [
    { id: 1, label: "Profile Based" },
    { id: 2, label: "Activity Based" },
  ];

  const dropdownLabel = dropdownOptions.find((option) => option.id === fypTab);

  function handleShowDropdown() {
    setShowDropdown(!showDropdown);
  }

  return (
    <SelectContainer>
      <SelectHeader
        onClick={() => handleShowDropdown()}
        $disabled={!isLoggedIn}
      >
        {dropdownLabel.label}
        <DownArrowIcon selected={showDropdown} />
      </SelectHeader>

      <SelectDropdown open={showDropdown}>
        {dropdownOptions.map((option, index) => (
          <SelectOption
            key={index}
            onClick={() => handleFypTabChange(option.id)}
          >
            {option.label}
          </SelectOption>
        ))}
      </SelectDropdown>
    </SelectContainer>
  );
}

export default NewJobListWrapper;
